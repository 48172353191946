import { Div } from "@max/common-ui";
import { useAppContext } from "AppContextProvider";
import { shoutoutI } from "firebase-tools";
import styled from "styled-components";
import { SessionMainFields } from "./SessionMainFields";
import { useSessionFormContext } from "../SessionFormContextProvider";

const Container = styled(Div)`
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.24);
  padding: 24px;
  border-radius: 8px;
  @media only screen and ${(props) => props.theme.media.mobile} {
    box-shadow: none;
    padding: 22px 0 32px 20px;
  }
`;

const TeammateChip = styled(Div)`
  display: inline-block;
  box-sizing: border-box;
  height: 40px;
  padding: 10px 15px;
  border: 1px solid #e6e9eb;
  border-radius: 20px;
  background-color: #ffffff;
`;

const Avatar = styled.img`
  height: 14px;
  width: 14px;
  border-radius: 100%;
  user-select: none;
`;

const TeammatesWrapper = styled(Div)`
  border-top: solid 1px ${(props) => props.theme.colors.gray10};
  padding-top: 12px;
  padding-bottom: 20px;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray10};
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const FieldWrapper = styled(Div)`
  display: flex;
  align-items: center;
  font-weight: 600;
  height: 56px;
  padding: 0 12px;
  input {
    font-weight: 600;
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    padding: 0;
    font-size: 15px;
    line-height: 19px;
    input {
      font-size: 15px;
      line-height: 19px;
    }
  }
`;

const SessionTitle = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    font-size: 17px;
    line-height: 22px;
    margin: 0 0 8px 0;
  }
`;

const Teammates = ({ teammates }) => {
  return (
    <TeammatesWrapper mt_8>
      {teammates.map(({ name, photo }, index) => (
        <TeammateChip mt_8 key={index} mr_10>
          <Div alignCenter h100>
            <Div dflex alignCenter>
              <Div fs_17 c_text>
                {name}
              </Div>
            </Div>
          </Div>
        </TeammateChip>
      ))}
    </TeammatesWrapper>
  );
};

const SessionSummary = ({
  id,
  index,
  startDate,
  startTime,
  maximumAttendees,
  callDurationSeconds,
  teammates,
  pricePerFan,
}) => {
  const { sessionData, setSessionData } = useSessionFormContext();
  const { setAppIsLoading } = useAppContext();

  const removeSession = async () => {
    setAppIsLoading(true);
    const {
      result,
      error,
    } = await shoutoutI.functions().meetgreet.cancelSession({ sessionId: id });
    if (result) setSessionData(sessionData.filter((s, i) => i !== index));
    if (error) {
      console.log({ error });
      alert(error);
    }
    setAppIsLoading(false);
  };

  return (
    <Container>
      <SessionTitle fs_20 lh_26 bold c_text mb_16>
        Session {index + 1}
      </SessionTitle>
      <SessionMainFields
        {...{
          startDate,
          startTime,
          callDurationSeconds,
          maximumAttendees,
          pricePerFan,
        }}
      />
      {teammates.length > 0 && <Teammates teammates={teammates} />}
      <Div dInline mt_35 c_red20 bold clickable onClick={removeSession}>
        Remove Session {index + 1}
      </Div>
    </Container>
  );
};

export default SessionSummary;
