import {
  Div,
  Modal as ModalBase,
  ModalBackdrop,
  ModalContainer,
  ModalWrapper,
} from "@max/common-ui";
import { useAppContext } from "AppContextProvider";
import { fstore } from "firebase-tools";
import { Input } from "notes";
import { useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import styled from "styled-components";
import { ShareUrl } from "./common/ShareUrl";
import { useEventsAdminContext } from "./EventsAdminContextProvider";

const Modal = styled(ModalBase)`
  ${ModalBackdrop} {
    z-index: 9999;
  }
  ${ModalContainer} {
    margin-top: 55px;
    z-index: 10000;
  }
  ${ModalWrapper} {
    width: 472px;
    @media all and ${(props) => props.theme.media.mobile} {
      width: 60vw;
      z-index: 10000;
    }
  }
`;

const Divider = styled(Div)`
  height: 2px;
  width: 100%;
  background: ${(props) => props.theme.colors.gray10};
`;

const noteText =
  "Note: the title of your meet & greet is only visible to you and your teammates. It will not be visible to your fans and can be changed at any time.";

const NewEventModal = (props) => {
  const { newEventId } = useAppContext();

  const [event, isLoadingEvent]: any = useDocumentData(
    fstore.doc(`meet_greet_events/${newEventId}`)
  );
  return (
    <>
      {!isLoadingEvent && event && (
        <NewEventModalWithData
          eventName={event?.name || event?.artist}
          {...props}
        />
      )}
    </>
  );
};

const NewEventModalWithData = ({ isOpen, eventName, onClose }) => {
  const { setAppIsLoading } = useAppContext();
  const { newEventId } = useAppContext();
  const { onEventNameUpdate } = useEventsAdminContext();
  const [inputValue, setInputValue] = useState(eventName);

  const onSubmit = async () => {
    if (!inputValue) return;
    if (inputValue !== eventName) {
      setAppIsLoading(true);
      onEventNameUpdate(newEventId, inputValue);
      await fstore.doc(`meet_greet_events/${newEventId}`).set(
        {
          name: inputValue,
        },
        { merge: true }
      );
      setAppIsLoading(false);
    }
    onClose();
  };

  const shareUI = (
    <Div textAlignCenter c_text>
      <Div fs_25 extraBold>
        {"Meet & Greet Created!"}
      </Div>
      <Div centered>
        <Div w_392 lite fs_17 mt_15 mb_40>
          {"How would you like to share your upcoming meet & greet with fans?"}
        </Div>
      </Div>
      <Div centered>
        <ShareUrl
          url={`${process.env.REACT_APP_TICKET_SITE_URL}/${newEventId}`}
        />
      </Div>
    </Div>
  );

  const eventNameInputUI = (
    <>
      <Div fs_15 c_text mb_8>
        {"What would you like to call your meet & greet?"}
      </Div>
      <Input
        error={!inputValue}
        autoFocus
        value={inputValue}
        onChange={(v) => setInputValue(v)}
      />
    </>
  );

  const doneButtonUI = (
    <>
      <Div mt_40 mb_15 centered>
        <Div link c_action fs_17 bold onClick={onSubmit}>
          Done
        </Div>
      </Div>
    </>
  );

  return (
    <Modal withCloseIcon={false} {...{ isOpen, onClose }}>
      {shareUI}
      <Divider mt_38 mb_38 />
      {eventNameInputUI}
      <Div fs_11 c_gray20 lh_17 mt_10>
        {noteText}
      </Div>
      {doneButtonUI}
    </Modal>
  );
};

export default NewEventModal;
