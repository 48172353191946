import { Div } from "@max/common-ui";
import { usePreviousObj } from "components/EventsAdmin/SessionDetail/SessionTeammates";
import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { DragArea } from "./DragArea";
import { Draggable as DraggableBase } from "./Draggable";

const Image = styled("img")`
  width: 100%;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
`;

const DraggableLogo = styled<any>(DraggableBase)`
  outline: 1px dashed black;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: ${(props) => props.width}px;
  ${(props) =>
    props.isResizing &&
    css`
      pointer-events: none;
    `}
`;

const DraggableResizeHandle = styled<any>(DraggableBase)`
  width: 3px;
  height: ${(props) => props.height}px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  cursor: ew-resize;
  :hover {
    opacity: 0.8;
    cursor: ew-resize;
  }
  :active {
    cursor: ew-resize;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ResizeHandleIndicator = styled(Div)`
  border: solid 1px white;
  background: black;
  height: 8px;
  width: 8px;
  position: absolute;
`;

const RightResizeHandleIndicator = styled(ResizeHandleIndicator)`
  margin-right: 2px;
`;

const LeftResizeHandleIndicator = styled(ResizeHandleIndicator)`
  margin-left: -5px;
`;

const DragAreaWrapper = styled(Div)`
  display: inline-block;
`;

const Container = styled(Div)`
  ${(props) =>
    props.isResizing &&
    css`
      cursor: ew-resize;
    `}
`;

const WIDTH_RESIZE_THRESHOLD = 30;
const sleep = (n) => new Promise((resolve) => setTimeout(resolve, n));

const WatermarkEditor = ({
  selectedImg = null,
  width,
  height,
  backgroundUI,
  onUpdate,
  initialPosition = null,
}) => {
  const logoRef = useRef(null);
  const [isResizing, setIsResizing] = useState(false);
  const [logoWidth, setLogoWidth] = useState(width / 5);
  const [logoPosition, setLogoPosition] = useState({ left: 0, top: 0 });
  const [rightResizeHandlePosition, setRightResizeHandlePosition] = useState({
    left: 0,
    top: 0,
  });
  const [leftResizeHandlePosition, setLeftResizeHandlePosition] = useState({
    left: 0,
    top: 0,
  });

  const getLogoRect = () => logoRef?.current?.getBoundingClientRect();
  const getLogoAspectRatio = () => {
    const logoBoundingRect = getLogoRect();
    return logoBoundingRect?.width / logoBoundingRect?.height;
  };

  const previousLogoPosition = usePreviousObj(logoPosition);
  const previousLogoWidth = usePreviousObj(logoWidth);
  useEffect(() => {
    if (previousLogoPosition && previousLogoWidth) {
      const logoHeight = logoWidth / getLogoAspectRatio();
      onUpdate({
        top: logoPosition.top / height,
        left: logoPosition.left / width,
        width: logoWidth / width,
        height: logoHeight / height,
      });
    }
  }, [logoWidth, logoPosition]);

  useEffect(() => {
    const init = async () => {
      let _initialPosition = {
        left: 0,
        top: 0,
        width: width / 5,
      };
      if (initialPosition) {
        _initialPosition = {
          top: initialPosition.top * height,
          left: initialPosition.left * width,
          width: initialPosition.width * width,
        };
      }
      if (selectedImg) {
        // wait for selected image to display on div
        await sleep(100);
        setLogoPosition({
          top: _initialPosition.top,
          left: _initialPosition.left,
        });
        setLeftResizeHandlePosition({
          top: _initialPosition.top,
          left: _initialPosition.left,
        });
        setRightResizeHandlePosition({
          top: _initialPosition.top,
          left: _initialPosition.left + _initialPosition.width,
        });
        setLogoWidth(_initialPosition.width);
      }
    };
    init();
  }, [selectedImg]);

  const onChangeLogoPosition = ({ left, top }) => {
    setLogoPosition({ left, top });
    setRightResizeHandlePosition({ left: left + logoWidth, top });
    setLeftResizeHandlePosition({ left: left, top });
  };

  const onChangeResizeRightHandlePosition = ({ left: resizeHandleLeft }) => {
    const logoDimensionsRatio = getLogoAspectRatio();

    const newLogoHeight =
      (resizeHandleLeft - logoPosition.left) / logoDimensionsRatio;
    const isResizedLogoHeightInvalid =
      logoPosition.top + newLogoHeight > height;

    const updateLogoAndHandleDimensions = (newLogoWidth, _resizeHandleLeft) => {
      setLogoWidth(newLogoWidth);
      setRightResizeHandlePosition((previous) => ({
        top: previous.top,
        left: _resizeHandleLeft,
      }));
    };

    if (isResizedLogoHeightInvalid) {
      const maxHeight = height - logoPosition.top;
      const newLogoWidth = maxHeight * logoDimensionsRatio;
      const _resizeHandleLeft = logoPosition.left + newLogoWidth;
      updateLogoAndHandleDimensions(newLogoWidth, _resizeHandleLeft);
    } else if (resizeHandleLeft >= logoPosition.left + WIDTH_RESIZE_THRESHOLD) {
      const newLogoWidth = resizeHandleLeft - logoPosition.left;
      updateLogoAndHandleDimensions(newLogoWidth, resizeHandleLeft);
    }
  };

  const onChangeResizeLeftHandlePosition = ({ left: resizeHandleLeft }) => {
    const logoDimensionsRatio = getLogoAspectRatio();

    const newLogoHeight =
      (rightResizeHandlePosition.left - resizeHandleLeft) / logoDimensionsRatio;
    const isResizedLogoHeightInvalid =
      logoPosition.top + newLogoHeight > height;

    const updateLogoAndHandleDimensions = (newLogoWidth, _resizeHandleLeft) => {
      setLogoWidth(newLogoWidth);
      setLogoPosition((p) => ({ left: _resizeHandleLeft, top: p.top }));
      setLeftResizeHandlePosition((previous) => ({
        top: previous.top,
        left: _resizeHandleLeft,
      }));
      setRightResizeHandlePosition((previous) => ({
        top: previous.top,
        left: _resizeHandleLeft + newLogoWidth,
      }));
    };

    if (isResizedLogoHeightInvalid) {
      const maxHeight = height - logoPosition.top;
      const newLogoWidth = maxHeight * logoDimensionsRatio;
      const _resizeHandleLeft = rightResizeHandlePosition.left - newLogoWidth;
      updateLogoAndHandleDimensions(newLogoWidth, _resizeHandleLeft);
    } else if (
      rightResizeHandlePosition.left - resizeHandleLeft >=
      WIDTH_RESIZE_THRESHOLD
    ) {
      const newLogoWidth = rightResizeHandlePosition.left - resizeHandleLeft;
      updateLogoAndHandleDimensions(newLogoWidth, resizeHandleLeft);
    }
  };

  return (
    <Container isResizing={isResizing} onMouseUp={() => setIsResizing(false)}>
      <DragAreaWrapper>
        <DragArea width={width} height={height}>
          <Div h100 w100 selectNone>
            {backgroundUI}
          </Div>
          {selectedImg && (
            <>
              <DraggableLogo
                width={logoWidth}
                position={logoPosition}
                isResizing={isResizing}
                onChangePosition={onChangeLogoPosition}
              >
                <Div ref={logoRef} dflex w100 draggable={false}>
                  <Image draggable={false} src={selectedImg} />
                </Div>
              </DraggableLogo>
              <Div onMouseDown={() => setIsResizing(true)}>
                <DraggableResizeHandle
                  height={logoWidth / getLogoAspectRatio()}
                  position={leftResizeHandlePosition}
                  onChangePosition={onChangeResizeLeftHandlePosition}
                >
                  <LeftResizeHandleIndicator />
                </DraggableResizeHandle>
              </Div>
              <Div onMouseDown={() => setIsResizing(true)}>
                <DraggableResizeHandle
                  height={logoWidth / getLogoAspectRatio()}
                  position={rightResizeHandlePosition}
                  onChangePosition={onChangeResizeRightHandlePosition}
                >
                  <RightResizeHandleIndicator />
                </DraggableResizeHandle>
              </Div>
            </>
          )}
        </DragArea>
      </DragAreaWrapper>
    </Container>
  );
};

export default WatermarkEditor;
