import { AppContextProvider } from "AppContextProvider";
import firebase from "firebase-tools";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "Routes";
import { ThemeProvider } from "styled-components";
import { theme } from "theme";
import { UserContextProvider } from "UserContextProvider";
import "./App.css";

import { UserProvider } from "auth";

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <AppContextProvider>
          <UserProvider>
            <UserContextProvider>
              <Routes />
            </UserContextProvider>
          </UserProvider>
        </AppContextProvider>
      </ThemeProvider>
    </Router>
  );
};

export default App;
