import {
  AppContainer,
  AppLayout as AppLayoutBase,
  AppLoading,
  Div,
  Footer,
  MobileDivider,
} from "@max/common-ui";
import { useState } from "react";
import styled from "styled-components";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { fstore } from "firebase-tools";
import { H3, FlexColumn, Icon, FlexRow, Link } from "notes";
import { EditCard } from "./EditCard";
import { ContactForm } from "./ContactForm";
import { useUserContext } from "../../UserContextProvider";
import Navigation from "components/common/Navigation";
import { useHistory } from "react-router";

export const MyAccount = () => {
  const { user } = useUserContext();
  const [profile, loading, error]: any = useDocumentData(
    fstore.doc(`profiles/${user?.uid}`)
  );
  return (
    <>
      {loading || !profile ? (
        <AppLoading />
      ) : (
        <MyAccountWithData profile={profile} />
      )}
    </>
  );
};

const MyAccountWithData = ({ profile }) => {
  const { user } = useUserContext();
  const [edit, setEdit] = useState({ contact: false, password: false });
  const history = useHistory();

  const { fullName, name, email } = profile;

  const defaultEmail = email ?? user.email;

  const defaultName = fullName ?? name.firstName + " " + name.lastName;

  const handleState = (key, value) => {
    const newValue = { ...edit, [key]: value };
    setEdit(newValue);
  };

  const breadcrumbs = [{ label: "Back", onClick: () => history.goBack() }];

  return (
    <AppLayout>
      <Navigation showMenu />
      <Header>
        <Breadcrumbs>
          <Icon tertiary name="Previous" />
          {breadcrumbs.map(({ label, onClick }, index) => (
            <BreadcrumbLink key={index} onClick={onClick}>
              {label}{" "}
            </BreadcrumbLink>
          ))}
        </Breadcrumbs>
        <H3>My Account</H3>
      </Header>
      <AppContainer>
        <MobileDivider />
        <Container>
          <CardsContainer stretch>
            <EditCard
              title="Contact Information"
              action="Edit"
              setEdit={() => handleState("contact", true)}
              edit={edit?.contact}
            >
              {edit?.contact ? (
                <ContactForm
                  defaultValue={{
                    fullName: defaultName,
                    email: defaultEmail,
                  }}
                  onComplete={() => handleState("contact", false)}
                />
              ) : (
                <>
                  <Div bold mb_4>
                    {defaultName}
                  </Div>
                  <GrayText lite>{defaultEmail}</GrayText>
                </>
              )}
            </EditCard>
            <MobileDivider />
          </CardsContainer>
        </Container>
      </AppContainer>
      <Footer />
    </AppLayout>
  );
};

const Header = styled(FlexColumn)`
  background: #ffffff;
  padding: 22px 20px 32px 20px;
  ${H3} {
    font-size: 20px;
    font-weight: 800;
    line-height: 26px;
    margin: 0;
  }
  @media only screen and ${(props) => props.theme.media.desktop} {
    border-bottom: 2px solid ${(props) => props.theme.palette.gray.lightest};
    padding: 40px 24px 44px 144px;
    ${H3} {
      font-size: 25px;
      line-height: 32px;
    }
  }
`;

const Breadcrumbs = styled(FlexRow)`
  color: ${(props) => props.theme.palette.gray.primary};
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 36px;
  svg {
    margin-top: 1px;
    margin-right: 4px;
    width: 12px;
    height: 12px;
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    margin-bottom: 24px;
  }
`;

const BreadcrumbLink = styled(Link)`
  color: ${(props) => props.theme.palette.gray.primary};
  font-size: 11px;
  font-weight: 700;
  line-height: 14px;
  margin-right: 4px;
  &:hover {
    color: ${(props) => props.theme.palette.gray.primary};
    text-decoration: underline;
  }
`;

const CardsContainer = styled(FlexColumn)`
  width: 100%;
  max-width: 704px;
  @media only screen and ${(props) => props.theme.media.mobile} {
    flex: 1 0 auto;
    width: 100%;
    max-width: initial;
  }
`;

const GrayText = styled(Div)`
  color: ${(props) => props.theme.palette.gray.primary};
`;

const AppLayout = styled(AppLayoutBase)`
  background-color: #fafafa;
  height: initial;
  min-height: 100vh;
  grid-template-rows: auto auto 1fr auto;
  @media only screen and ${(props) => props.theme.media.mobile} {
    background-color: #ffffff;
  }
`;

const Container = styled(FlexColumn)`
  width: 100%;
  @media only screen and ${(props) => props.theme.media.desktop} {
    padding-top: 56px;
    padding-bottom: 96px;
  }
`;
