import {
  Div,
  Modal as ModalBase,
  ModalBackdrop,
  ModalContainer,
  ModalWrapper,
} from "@max/common-ui";
import React from "react";
import styled, { css } from "styled-components";

const Modal = styled(ModalBase)`
  ${ModalBackdrop} {
    background-color: transparent;
  }
  ${ModalContainer} {
    margin-top: 45px;
    @media all and ${(props) => props.theme.media.mobile} {
      margin-top: 70px;
    }
  }
  ${ModalWrapper} {
    border-radius: 8px;
    width: 392px;
    @media all and ${(props) => props.theme.media.mobile} {
      width: 220px;
    }
  }
`;

const Message = styled(Div)`
  font-weight: 300;
  ${(props) =>
    props.isBold &&
    css`
      font-weight: 600;
    `}
`;

const AlertModal = ({
  message,
  isOpen,
  onClose,
  isBold = true,
  title = "",
}) => {
  return (
    <Modal {...{ withCloseIcon: false, isOpen, onClose }}>
      {title && (
        <Div centered bold fs_17 mb_7>
          {title}
        </Div>
      )}
      <Message centered isBold={isBold}>
        {message}
      </Message>
      <Div c_action link centered mt_25 onClick={onClose}>
        OK
      </Div>
    </Modal>
  );
};

export default AlertModal;
