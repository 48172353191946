import { useUser } from "auth";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { ROUTES } from "Routes";

export const Login = () => {
  const { isLoggedIn, redirectToLogin } = useUser();
  const history = useHistory();

  useEffect(() => {
    if (!isLoggedIn) redirectToLogin();
    else history.push(ROUTES.ARTISTS_GROUPS);
  }, []);

  return <></>;
};
