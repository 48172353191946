import {
  Div,
  Overlay as DropdownDiv,
  OverlayBackdrop as Backdrop,
  useIsMobile,
} from "@max/common-ui";
import { Icon } from "notes";
import { useState } from "react";
import { generatePath, useHistory } from "react-router";
import { ROUTES } from "Routes";
import styled, { css } from "styled-components";
import { useUserContext } from "UserContextProvider";

const UserMenuWrapper = styled(Div)`
  display: flex;
  height: 40px;
`;

const Dropdown = styled(DropdownDiv)`
  width: 200px;
  border-radius: 4px;
  margin-left: -200px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.24);
`;

const UserMenu = () => {
  const { user, signOut } = useUserContext();
  const [isUserProfileDropdownOpen, setIsUserProfileDropdownOpen] = useState(
    false
  );
  const isMobile = useIsMobile();
  const history = useHistory();

  const gotoPage = (path) => {
    history.push(path);
    setIsUserProfileDropdownOpen(false);
  };

  const dropdownUI = (
    <Div>
      {isUserProfileDropdownOpen && (
        <>
          <Backdrop onClick={() => setIsUserProfileDropdownOpen(false)} />
          <Dropdown>
            <Div w100>
              <UserMenuItem
                onClick={() => {
                  window.location.href =
                    "https://" + process.env.REACT_APP_FIREBASE_AUTHDOMAIN;
                }}
              >
                <Div ml_2> My Account</Div>
              </UserMenuItem>
              <UserMenuItem
                onClick={() =>
                  history.push(generatePath(`${ROUTES.ARTISTS_GROUPS}`))
                }
              >
                <Div ml_2>My Artists</Div>
              </UserMenuItem>
              <UserMenuItem onClick={() => gotoPage(ROUTES.ARTIST_MANAGEMENT)}>
                <Div ml_2>Artist Management</Div>
              </UserMenuItem>
            </Div>
            <Div w100>
              <UserMenuItem isLast={true} onClick={() => signOut()}>
                <Div>Sign Out</Div>
              </UserMenuItem>
            </Div>
          </Dropdown>
        </>
      )}
    </Div>
  );

  const dropdownIconDim = isMobile ? "13px" : "16px";
  return (
    <UserMenuWrapper>
      <UserProfileWrapper
        onClick={() => {
          setIsUserProfileDropdownOpen(true);
        }}
      >
        <Div mr_10 centered h100>
          {user?.photoURL ? (
            <Div centered>
              <Avatar src={user?.photoURL} />
            </Div>
          ) : (
            <Placeholder centered>
              {user?.displayName
                ? user?.displayName[0]
                : user?.email[0].toUpperCase()}
            </Placeholder>
          )}
        </Div>
        <Div alignCenter>
          <Icon
            indicator
            name="Dropdown"
            height={dropdownIconDim}
            width={dropdownIconDim}
          />
        </Div>
      </UserProfileWrapper>
      {dropdownUI}
    </UserMenuWrapper>
  );
};

const DisplayName = styled(Div)`
  border-bottom: solid 1px transparent;
`;

const UserProfileWrapper = styled(Div)`
  svg {
    path {
      fill: currentColor;
    }
  }
  display: flex;
  align-items: center;
  font-weight: bold;
  user-select: none;
  ${DisplayName} {
    border-bottom: solid 2px transparent;
  }

  :hover {
    opacity: 0.9;
    cursor: pointer;
    ${DisplayName} {
      border-bottom: solid 2px white;
    }
  }
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
`;

const UserMenuItem = styled(Div)`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 17px;
  height: 48px;
  color: #222222;
  user-select: none;
  font-weight: 600;
  padding: 0px 16px;
  ${(props) =>
    !props.isLast &&
    css`
      border-bottom: solid 1px ${(props) => props.theme.palette.gray.lightest};
    `}
  :hover {
    background-color: #f5f5f5;
    cursor: pointer;
    opacity: 0.9;
  }
`;

const Avatar = styled.img`
  border-radius: 100%;
  height: 35px;
  width: 35px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-right: -3px;
    height: 23px;
    width: 23px;
  }
`;

const Placeholder = styled(Div)`
  height: 35px;
  width: 35px;
  background-color: #fe8224;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 100%;
`;

export default UserMenu;
